import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header: {
        height: 50,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    body: {
        flex: 1,
        position: "relative",
    },
    bodyScrollView: {
        flex: 1,
    },
    imagesContainer: {
        marginTop: 50,
        marginBottom: 50,
    },
    logoContainerForCustomVenueLayout: {
        marginTop: 50,
        marginBottom: 50,
        height: 80,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    imagesContainerForCustomVenueLayout: {
        width: "60%",
        display: "flex",
        flexDirection: "row",
    },
    bannerItem: {
        height: 175,
        width: '100%',
        aspectRatio: 500 / 1024,
        resizeMode: "contain",
    },
    imageItem: {
        width: 100,
        height: 100
    },
    imagePromotionItemForCustomVenueLayout: {
        width: "65%",
        height: 60
    },
    imageLogoItemForCustomVenueLayout: {
        width: "80%",
        height: 85
    },
    logoSubtitle: {
        marginTop: 5,
        marginBottom: 20,
        paddingRight: 2,
        paddingLeft: 2,
        fontSize: 12,
        fontWeight: "700",
        lineHeight: 8,
        alignSelf: "center",
        textAlign: "center",
        textTransform: "uppercase",
        color: "#c9c9c9"
    },
    footer: {
        height: 50,
        width: "100%",
        alignItems: 'center',
    },
    venueName: {
        marginBottom: 20,
        paddingRight: 10,
        paddingLeft: 10,
        fontSize: 16,
        fontWeight: "700",
        lineHeight: 16,
        alignSelf: "center",
        textAlign: "center"
    },
    venueImageContainer: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    venueImageContainerForCustomVenueLayout: {
        flex: 0.4,
        alignItems: "center",
        justifyContent: "center"
    },
    textInput: {
        fontSize: 14,
        color: '#3f3a40',
        textAlign: 'left',
        borderColor: '#c9c9c9',
        backgroundColor: 'white',
        borderRadius: 20,
        height: 45,
        width: '90%',
        margin: 5,
        paddingHorizontal: 16,
        borderWidth: 1
    },
    confirmPaymentBtnContainer: {
        margin: 16,
        width: '90%',
        marginTop: 20,
        alignSelf: 'center',
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.34,
        shadowRadius: 6.27,
    },
    confirmPaymentBtn: {
        height: 67,
    },
    confirmPaymentBtnText: {
        fontSize: 18,
    },
    confirmPaymentBtnDisabled: {
        backgroundColor: '#89909b',
    },
    dropdownPicker: {
        minHeight: 45,
        width: '90%',
        borderColor: "#c9c9c9",
        borderRadius: 20,
        alignSelf: 'center',
        marginBottom: 10
    },
    errorTextContainer:{ 
        padding: 8, 
        borderColor: "#f5c6cb", 
        backgroundColor: "#f8d7da", 
        borderRadius: 8 
    },
    errorText: {
        color: '#EC1C24',
        fontSize: 16,
    },
    termsAndConditionsContainer: {
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 15,
        alignItems: 'center',
    },
    termsAndConditionsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 2
    },
    externalLinkWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    termsAndConditionsLink: {
        padding: 5,
        width: 'max-content'
    },
    promotionsImageContainer: {
        flex: 1,
        borderColor: "#c9c9c9",
        borderRightWidth: 2,
        alignItems: "center",
        justifyContent: "center"
    },
    promotionsImageContainerForCustomVenueLayout: {
        flex: 0.7,
        alignItems: "center",
        justifyContent: "center"
    },
    borderWithinImageContainerForCustomVenueLayout: {
        height: 40,
        width: 1,
        backgroundColor: "#c9c9c9",
        color: "#c9c9c9",
        justifyContent: "center",
        alignSelf: "center",
    },
    videoContainer: {
        opacity: 1,
        height: "100%"
    },
    video: {
        height: "100%",
        width: "100%"
    },
    backgroundMainImage: {
        flex: 1,
    },
    backgroundHeaderImage: {
        height: 100,
        width: "100%",
        resizeMode: 'cover',
        alignItems: "center",
        justifyContent: "center",
    },
    backgroundFooterImage: {
        height: 100,
        width: "100%",
        resizeMode: 'cover',
        alignItems: "center",
        justifyContent: "center",
    },
});
