import { Animated, Image, Text, TouchableOpacity, View } from 'react-native';
import React, { useState } from 'react';
import Modal from "react-native-modal";

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import { AntDesign } from "@expo/vector-icons";

import styles from "styles/components/SmsValidationCode";
import Loading from './Loading';
import { validateSmsValidationCode } from 'services/authService';

const { Text: AnimatedText } = Animated;

const CELL_COUNT = 4;
const source = {
  uri: 'https://user-images.githubusercontent.com/4661784/56352614-4631a680-61d8-11e9-880d-86ecb053413d.png',
};


const SmsValidationCodeModal = ({ phone, show = false, onAction, resend, onClose }: { phone: string, show: boolean, onAction: any, resend: any, onClose: any }) => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidCodeMessage, setShowInvalidCodeMessage] = useState(false);
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const validateCode = async (code: string): Promise<void> => {
    const body = { phone, code: parseInt(code) };
    setIsLoading(true);

    try {
      const result = await validateSmsValidationCode(body);
      if (result) {

        //setShowSmsVerificationCodeModal(true);
        onAction();
      } else {
        setShowInvalidCodeMessage(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const renderCell = ({ index, symbol, isFocused }: any) => {
    return (
      <AnimatedText
        key={index}
        style={styles.cell}
        onLayout={getCellOnLayoutHandler(index)}>
        {symbol || (isFocused ? <Cursor /> : null)}
      </AnimatedText>
    );
  };

  return (
    <>
      <Modal isVisible={show}>
        {isLoading && <Loading />}
        <View style={{ backgroundColor: "#fff", borderRadius: 10, padding: 5 }}>
          <View>
            <AntDesign onPress={() => onClose()} style={{ alignSelf: "flex-end" }} name="close" size={30} color="black" />
          </View>
          <Text style={styles.title}>Verificación</Text>
          <Image style={styles.icon} source={source} />
          <Text style={styles.subTitle}>
            Por favor, ingrese el código{'\n'}
            que le enviamos por SMS
          </Text>
          <CodeField
            ref={ref}
            {...props}
            value={value}
            onChangeText={setValue}
            cellCount={CELL_COUNT}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={renderCell}
          />

          {showInvalidCodeMessage && (
            <Text style={styles.invalidCodeMessage}>Código incorrecto, vuelva a intentarlo</Text>
          )}
          <TouchableOpacity style={styles.nextButton} onPress={() => validateCode(value)}>
            <Text style={styles.nextButtonText}>Verificar</Text>
          </TouchableOpacity>

          <View>
            <Text style={styles.resendCode} onPress={() => resend()}>Reenviar código</Text>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default SmsValidationCodeModal;